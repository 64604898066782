@import "@/styles/main.scss";





















































































































































































.isContent {
  background: #eeeeee;
  font-family: "FZLTCHJW", sans-serif;
  .artcle-wrap-pic {
    height: 560px;
    background-size: cover;
    margin-bottom: 50px;
    position: relative;
    background-position: center;
    width: 100%;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: url(http://img6.cache.netease.com/game/img2018/play/img/img_bg.png)
        repeat-x;
      content: "";
      height: 560px;
    }
    .g-w1200 {
      width: 80%;
      margin: 0 auto;
      height: 560px;
      position: relative;
      overflow: hidden;
    }
    .article-h1 {
      bottom: 140px;
      font-size: 56px;
      color: #fff;
      line-height: 70px;
      position: absolute;
      font-weight: bold;
    }
    .art-about-new {
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      overflow: hidden;
      position: absolute;
      bottom: 40px;
      width: 100%;
    }
    .m-pingce {
      float: left;
      overflow: hidden;
    }
  }
  .dontai {
    font-size: 1.6rem;
    padding: 10px;
    font-weight: bold;
  }
  .golist {
    font-size: 1.2rem;
    padding: 14.5px;
    font-weight: bold;
    color: #6b6b6b;
    text-align: right;
  }
  .centitle {
    img {
      max-width: 100%;
      height: auto;
    }
    .title {
      font-size: 1.9rem;
      padding: 14.5px;
      font-weight: bold;
      color: #282828;
      margin-top: 10px;
    }
    .time {
      font-size: 1rem;
      // font-weight: bold;
      color: #282828;
    }
    .cent {
      margin-top: 30px;
      text-align: left;
      letter-spacing: 0.5px;
      line-height: 1.5rem;
    }
  }
  .groom {
    font-size: 1rem;
    font-weight: bold;
    color: #282828;
    text-align: left;
    padding: 14.5px;
  }
  .isgroomcen {
    ul {
      //   border: 1px solid #282828;
      li {
        overflow: hidden;
        text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
