@import "@/styles/main.scss";














































































































.islist {
  background: #eeeeee;
  .gamstetit {
    width: 100%;
    margin: 2rem 0px;
    .en {
      font-size: 1rem;
      color: #101010;
      font-family: "Helvetica", sans-serif;
    }
    .zh {
      font-size: 1.5rem;
      color: #101010;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
    }
  }
  .listcent {
    text-align: left;
    background: #fff;
    padding: 10px;
    height: 167px;
    .text {
      font-size: 1.3rem;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
    }
    .abstract {
      padding-top: 10px;
      font-size: 0.9rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 35px;
    }
    .time {
      border-top: 1px solid #e7e7e7;
      font-size: 0.9rem;
      // padding-top: 20px;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
      line-height: 4rem;
    }
  }
}
