.isHeadlist[data-v-5732c663] {
  background: #ff4949;
  height: 60px;
}
.isHeadlist a[data-v-5732c663] {
  font-weight: bold;
  color: #2c3e50;
}
.isHeadlist a.router-link-exact-active[data-v-5732c663] {
  color: #42b983;
}
.el-menu-item[data-v-5732c663] {
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
}
.el-menu-item[data-v-5732c663] :hover {
  border-bottom: solid 12px #e6e6e6;
}
.el-menu.el-menu--horizontal[data-v-5732c663] {
  border-bottom: solid 0px #e6e6e6;
}
.el-dropdown-menu__item[data-v-5732c663]:focus,
.el-dropdown-menu__item[data-v-5732c663]:not(.is-disabled):hover {
  background: #ff4949;
  color: #fff;
}
.ispcli[data-v-5732c663] {
  list-style-type: none;
  float: left;
  line-height: 60px;
  margin-top: -20px;
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
  height: 62px;
  padding: 0px 15px;
  text-align: center;
  color: #fff;
}
.ispcli a[data-v-5732c663] {
  color: #fff;
  text-decoration: none;
}
.ispcli[data-v-5732c663]:hover {
  border-bottom: solid 2px #e6e6e6;
  background: #bd3737;
}
.islist[data-v-dddc7e5c] {
  background: #eeeeee;
}
.islist .gamstetit[data-v-dddc7e5c] {
  width: 100%;
  margin: 2rem 0px;
}
.islist .gamstetit .en[data-v-dddc7e5c] {
  font-size: 1rem;
  color: #101010;
  font-family: "Helvetica", sans-serif;
}
.islist .gamstetit .zh[data-v-dddc7e5c] {
  font-size: 1.5rem;
  color: #101010;
  font-family: "FZLTCHJW", sans-serif;
  font-weight: bold;
}
.islist .listcent[data-v-dddc7e5c] {
  text-align: left;
  background: #fff;
  padding: 10px;
  height: 167px;
}
.islist .listcent .text[data-v-dddc7e5c] {
  font-size: 1.3rem;
  font-family: "FZLTCHJW", sans-serif;
  font-weight: bold;
}
.islist .listcent .abstract[data-v-dddc7e5c] {
  padding-top: 10px;
  font-size: 0.9rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 35px;
}
.islist .listcent .time[data-v-dddc7e5c] {
  border-top: 1px solid #e7e7e7;
  font-size: 0.9rem;
  font-family: "FZLTCHJW", sans-serif;
  font-weight: bold;
  line-height: 4rem;
}
.isContent[data-v-e99cc002] {
  background: #eeeeee;
  font-family: "FZLTCHJW", sans-serif;
}
.isContent .artcle-wrap-pic[data-v-e99cc002] {
  height: 560px;
  background-size: cover;
  margin-bottom: 50px;
  position: relative;
  background-position: center;
  width: 100%;
}
.isContent .artcle-wrap-pic[data-v-e99cc002]:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(http://img6.cache.netease.com/game/img2018/play/img/img_bg.png) repeat-x;
  content: "";
  height: 560px;
}
.isContent .artcle-wrap-pic .g-w1200[data-v-e99cc002] {
  width: 80%;
  margin: 0 auto;
  height: 560px;
  position: relative;
  overflow: hidden;
}
.isContent .artcle-wrap-pic .article-h1[data-v-e99cc002] {
  bottom: 140px;
  font-size: 56px;
  color: #fff;
  line-height: 70px;
  position: absolute;
  font-weight: bold;
}
.isContent .artcle-wrap-pic .art-about-new[data-v-e99cc002] {
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  overflow: hidden;
  position: absolute;
  bottom: 40px;
  width: 100%;
}
.isContent .artcle-wrap-pic .m-pingce[data-v-e99cc002] {
  float: left;
  overflow: hidden;
}
.isContent .dontai[data-v-e99cc002] {
  font-size: 1.6rem;
  padding: 10px;
  font-weight: bold;
}
.isContent .golist[data-v-e99cc002] {
  font-size: 1.2rem;
  padding: 14.5px;
  font-weight: bold;
  color: #6b6b6b;
  text-align: right;
}
.isContent .centitle img[data-v-e99cc002] {
  max-width: 100%;
  height: auto;
}
.isContent .centitle .title[data-v-e99cc002] {
  font-size: 1.9rem;
  padding: 14.5px;
  font-weight: bold;
  color: #282828;
  margin-top: 10px;
}
.isContent .centitle .time[data-v-e99cc002] {
  font-size: 1rem;
  color: #282828;
}
.isContent .centitle .cent[data-v-e99cc002] {
  margin-top: 30px;
  text-align: left;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}
.isContent .groom[data-v-e99cc002] {
  font-size: 1rem;
  font-weight: bold;
  color: #282828;
  text-align: left;
  padding: 14.5px;
}
.isContent .isgroomcen ul li[data-v-e99cc002] {
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  display: box;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/*# sourceMappingURL=about.0bf7dde3.css.map*/